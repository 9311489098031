import React, { Component } from 'react';

class Training extends Component {
  render() {

    if(this.props.data){
      var {academy} = this.props.data;
      console.log("ACADEMY: ", academy);
      var training = academy.training;
      var trainingRender = training.map(function(course) {
        return (
        <div key={course.title}>
          <h5>{course.title}</h5> 
          <p>{course.organization}, {course.date}</p>
        </div>)
      });
    }

    return (
      <section id="training">
      <div className="row">
        <div className="three columns header-col">
        <h1><span>Training</span></h1>
        </div>
        <br />
        <div className="nine columns main-col">
          {trainingRender}
        </div>
      </div>
      </section>
    );
  }
}

export default Training;
