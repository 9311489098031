import React, { Component } from 'react';
import ReactGA from 'react-ga';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Seminars from './Components/Seminars';
import Papers from './Components/Papers';
import Conferences from './Components/Conferences';
import Teaching from './Components/Teaching';
import Training from './Components/Training';
import Awards from './Components/Awards';



class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      resumeData:{}
    };
    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname);
    this.getResumeData = this.getResumeData.bind(this);
  }

  // https://storage.cloud.google.com/sitebordi.appspot.com/resumeData.json
  // anonymous request
  // https://storage.googleapis.com/sitebordi.appspot.com/resumeData.json
  getResumeData() {
    fetch('https://storage.googleapis.com/sitebordi.appspot.com/resumeData.json',
    {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: "GET"
    })
    .then((res) => {
      console.log("RESPONSE: ", res);
      let parsed = res.json();
      console.log("RESPONSE PARSED: ", parsed);
      return parsed;
    })
    .then((data) => {
        console.log("RESUME DATA: ", data);
        this.setState({resumeData: data});
      }
    )
    .catch((err) => {
      console.error(err);
    });
  }


  componentDidMount() {
    this.getResumeData();
  }


  render() {
    return (
      <div className="App">
        <Header data={this.state.resumeData? this.state.resumeData.main: null}/>
        <About data={this.state.resumeData? this.state.resumeData.main: null}/>
        <Resume data={this.state.resumeData? this.state.resumeData.resume : null}/>
        <Papers data={this.state.resumeData? this.state.resumeData.resume : null}/>
        <Conferences data={this.state.resumeData? this.state.resumeData.resume: null}/>
        <Seminars data={this.state.resumeData? this.state.resumeData.resume: null}/>
        <Awards data={this.state.resumeData? this.state.resumeData.resume: null}/>
        <Teaching data={this.state.resumeData? this.state.resumeData.resume: null}/>
        <Training data={this.state.resumeData? this.state.resumeData.resume: null}/>
        <Footer data={this.state.resumeData? this.state.resumeData.main: null}/>
      </div>
    );
  }
}

export default App;
