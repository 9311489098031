import React, { Component } from 'react';

class Seminars extends Component {
  render() {

    if(this.props.data){
      const {academy} = this.props.data;
      const seminars = academy.seminars;
      var seminarsRender = seminars.map(function(seminar){
        return (<p key={seminar.university + seminar.subject + seminar.days}>
        {seminar.university}, <i>{seminar.subject}</i>, {seminar.days}
        </p>)
      });  
    }

    return (
        <section id="seminars">
        <div className="row">
          <div className="three columns header-col">
          <h1><span>Seminars</span></h1>
          </div>
          <div className="nine columns main-col">
            {seminarsRender}
          </div>
        </div>
        <hr />
        </section>
    );
  }
}

export default Seminars;
