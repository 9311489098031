import React, { Component } from 'react';

class Papers extends Component {
  render() {
    if(this.props.data){
        // console.log("PAPERS DATA: ", this.props.data)
        const {academy} = this.props.data
        const papers = academy.papers;
        var papersRender = papers.map(function(paper){
        return (
        <div key={paper.title + paper.reference}>
          <h5>
              <a href={paper.reference}>
                {paper.title}
              </a>
          </h5>
          <div>
            <div className="info">{paper.name}, {paper.journal}, <em className="date">{paper.year}</em>
              <span style={{"float":"right"}}>
                <a href={paper.reference} className="button">click to open</a>
              </span>
            </div>
          </div>
          <hr />
        </div>
        )
        });
    }

    return (
    <section id="papers">
        <div className="row papers">
            <div className="three columns header-col">
                <h1><span>Papers</span></h1>
            </div>
            <div className="nine columns main-col">
                {papersRender}
            </div>
        </div>
   </section>
    );
  }
}

export default Papers;