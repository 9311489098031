import React, { Component } from 'react';

class Conferences extends Component {
  render() {

    if(this.props.data){
      // console.log("CONFERENCE DATA: ", this.props.data)
      const {academy} = this.props.data
      const conferences = academy.conferences;
      var conferenceRender = conferences.map(function(conference){
        return (<div key={conference.title + conference.date + conference.country}>
          <h5><span>{conference.title}</span></h5>
          <i>{conference.date}</i>
          <div className="info">{conference.where}, {conference.country}</div>
          <div>Contribute: {conference.contribute}</div>
        </div>)
      });
    }

    return (
        <section id="conferences">
        <div className="row">
          <div className="three columns header-col">
          <h1><span>Conferences</span></h1>
          </div>
          <div className="nine columns main-col">
            {conferenceRender}
          </div>
        </div>
        <hr />
        </section>
    );
  }
}

export default Conferences;
