import React, { Component } from 'react';

class About extends Component {
  render() {

    if(this.props.data){
      // console.log("ABOUT DATA: ", this.props.data)
      var profilepic= "images/"+this.props.data.image;
      var bio = this.props.data.bio;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var universityemail = this.props.data.univeristyemail;
      var resumeDownload = this.props.data.resumedownload;
      var universitypersonalpage = this.props.data.universitypersonalpage;
      var universitygrouppage = this.props.data.universitygrouppage;
    }

    return (
      <section id="about">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic" src={profilepic} alt="Tim Baker Profile Pic" />
         </div>
         <div className="nine columns main-col">
            <h2>About Me</h2>

            <p>{bio}</p>
            <div className="row">
               <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <ul>
                     <li>Personal email address:<br/>
                        <span><a href={"mailto:"+email}>{email}</a></span></li>
                     <li>University email address:<br/>
                        <span><a href={"mailto:"+universityemail}>{universityemail}</a></span></li>
						   <li>Phone number:<br/>
                        <span><a href={"tel:"+phone}>{phone}</a></span></li>
                     <li>Official personal page: <br />
                        <span><a href={universitypersonalpage} className="link"> click here</a></span></li>
                     <li>Number Theory group page: <br />
                        <span><a href={universitygrouppage} className="link"> click here</a></span></li>
					   </ul>
               </div>
               <div className="columns download">
                  <p>
                     <a href={resumeDownload} className="button"><i className="fa fa-download"></i>Download Resume</a>
                  </p>
               </div>
            </div>
         </div>
      </div>

   </section>
    );
  }
}

export default About;
