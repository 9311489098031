import React, { Component } from 'react';

class Awards extends Component {
  render() {

    if(this.props.data){
      var {academy} = this.props.data;
      var awards = academy.awards;
      var awardsRender = awards.map(function(award) {
        return (
            <div>
                <h5 key={award.title}>
                    <a href={award.link}>
                        {award.title}
                    </a>
                </h5>
                <div>
                    {award.organization}, {award.date}
                </div>
            </div>
        )
      });
    }

    return (
      <section id="awards">
      <div className="row">
        <div className="three columns header-col">
        <h1><span>Awards</span></h1>
        </div>
        <div className="nine columns main-col">
          {awardsRender}
        </div>
      </div>
      </section>
    );
  }
}

export default Awards;