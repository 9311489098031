import React, { Component } from 'react';

class Teaching extends Component {
  render() {

    if(this.props.data){
      var {academy} = this.props.data;
      var teaching = academy.teaching;
      var teachingRender = teaching.map(function(lecture) {
        return (
        <div key={lecture.code}>
          <h5>{lecture.title}</h5> {lecture.code}
        </div>)
      });
    }

    return (
      <section id="teaching">
      <div className="row">
        <div className="three columns header-col">
        <h1><span>Teaching</span></h1>
        </div>
        I have tutored the following courses during my PhD candidature at UNSW Canberra:
        <br />
        <div className="nine columns main-col">
          {teachingRender}
        </div>
      </div>
      </section>
    );
  }
}

export default Teaching;
